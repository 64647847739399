.rider-tracking-container {
  display: flex;
  height: 100vh;
}

.rider-list {
  width: 25%;
  overflow-y: auto;
  background-color: #f5f5f5;
}

.rider-list h2 {
  text-align: center;
  padding: 1rem;
  margin: 0;
  background-color: #3f51b5;
  color: white;
}

.rider-list ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.rider-list li {
  padding: 1rem;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
  transition: background-color 0.3s;
}

.rider-list li:hover {
  background-color: #e0e0e0;
}

.rider-map {
  width: 75%;
  padding: 1rem;
}

.rider-map h2 {
  text-align: center;
  padding: 1rem;
  margin: 0;
  background-color: #3f51b5;
  color: white;
}
